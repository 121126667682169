import momenttz from "moment-timezone";

import {ConversationsListSearchParamsType} from "~reducers/businessOwner/messenger";
import {formatDate} from "~utils/formatters/date";

import {MessengerFilterTabsNames, MessengerQueryKeys} from "./constants";

export const evaluateFilterTabName = ({
  tabName,
  unreadConversationsCount,
}: {
  tabName: MessengerFilterTabsNames;
  unreadConversationsCount: number;
}) => {
  switch (tabName) {
    case MessengerFilterTabsNames.All:
      return tabName;
    case MessengerFilterTabsNames.Unread:
      return `${tabName} ${
        unreadConversationsCount > 0 ? `(${unreadConversationsCount})` : ""
      }`;
  }
};

export const getConversationDateString = (date: string): string => {
  const timeZone = momenttz.tz.guess();
  const today = momenttz();
  const isDateToday = momenttz.tz(date, timeZone).isSame(today, "day");

  return isDateToday ? formatDate(date, "h:mm a") : formatDate(date, "MMM D");
};

export const getPagesConfiguration = ({
  currentPage,
  perPageLimit,
  totalItems,
}: {
  currentPage: number;
  perPageLimit: number;
  totalItems: number;
}): {
  from: number;
  to: number;
  totalPages: number;
} => {
  const totalPages = Math.ceil(totalItems / perPageLimit);

  if (totalPages === 0) {
    return {
      from: 0,
      to: 0,
      totalPages: 0,
    };
  }

  const from = (currentPage - 1) * perPageLimit + 1;
  const to =
    currentPage * perPageLimit >= totalItems ? totalItems : currentPage * perPageLimit;

  return {
    from,
    to,
    totalPages,
  };
};

export const getFirstLetters = (name: string): string => {
  return name
    .split(" ")
    .map((word) => word[0])
    .join("");
};

export const getTimeAgoFromNow = (date: string): string => {
  const timeZone = momenttz.tz.guess();
  const tzDate = momenttz.tz(date, timeZone);
  return tzDate.fromNow();
};

export const getConversationsListQueryKey = ({
  page,
  q,
  unread,
}: Partial<ConversationsListSearchParamsType>) => [
  MessengerQueryKeys.CONVERSATIONS_LIST,
  page,
  q,
  unread,
];
